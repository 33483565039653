import React from "react";
import CrossIcon from "../../../assets/icons/CrossIcon";
import clsx from "clsx";
import { MediaFiles, MediaType } from "../../../models";

interface MediaPlayerProps {
	media: MediaFiles;
	showPlayer: boolean | undefined;
	closeShowPlayer: () => void;
}

const MediaPlayer: React.FC<MediaPlayerProps> = ({ media, showPlayer, closeShowPlayer }) => {
	if (showPlayer)
		return (
			<div className="media-player-modal-container">
				<div className="modal-overlay" onClick={closeShowPlayer}></div>
				<div className="media-player-modal">
					<CrossIcon className="cross-icon medium-icon" onClick={closeShowPlayer} />
					<div
						className={clsx({
							"media-container": true,
							audio: media.mediaType == MediaType.Audio,
							video: media.mediaType == MediaType.Video,
						})}>
						{media.mediaType == MediaType.Audio && (
							<audio controls>
								<source src={media.fileUrl} type="audio/mp3" />
							</audio>
						)}
						{media.mediaType == MediaType.Video && (
							<video controls>
								<source src={media.fileUrl} type="audio/mp3" />
							</video>
						)}
					</div>
					<div className="info-container">
						<div className="title">{media.name}</div>
						<div className="autor">{media.autor}</div>
					</div>
				</div>
			</div>
		);

	return null;
};

export default MediaPlayer;
