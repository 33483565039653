import React from "react";
import { Chapter } from "../../../models";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import QrCodeIcon from "../../../assets/icons/QrCodeIcon";
import RedirectIcon from "../../../assets/icons/RedirectIcon";

interface ChaptersTableProps {
	chapters: Chapter[];
	download: () => void;
	view: () => void;
}

const ChaptersTable: React.FC<ChaptersTableProps> = ({ chapters, download, view }) => {
	return (
		<div className="chapters-table">
			{chapters.map((chapter) => (
				<div className="chapter-item" key={chapter.order}>
					<div className="chapter-name">
						<span>Unidad - {chapter.order}</span>
						<span>{chapter.title}</span>
					</div>
					<div className="chapters-actions">
						<DownloadIcon className="download-icon small-plus-icon" fill="#636363" onClick={download} />
						<ViewIcon className="-icon small-plus-icon" fill="#636363" onClick={view} />
						{chapter.qrCodes && <QrCodeIcon className="-icon small-plus-icon" />}
						{chapter.links && <RedirectIcon className="-icon small-plus-icon" />}
					</div>
				</div>
			))}
		</div>
	);
};

export default ChaptersTable;
