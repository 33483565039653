const formatDuration = (duration: number) => {
	const stringNumber = duration.toString();

	const hours = stringNumber.substr(0, 2).padStart(2, "0");
	const minutes = stringNumber.substr(2, 2).padStart(2, "0");
	const seconds = stringNumber.substr(4, 2).padStart(2, "0");
	return `${hours}:${minutes}:${seconds}`;
};

export default formatDuration;
