import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { Link, useParams, useLocation } from "react-router-dom";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";

interface BreadcrumbsProps {
	history: string[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ history: items }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	const handleRedirect = (index: number) => {
		const redirectPath = location.pathname
			.split("/")
			.filter((x) => x !== params.level)
			.slice(0, index + 1);

		const redirectLinks = redirectPath.map((x) => {
			if (x == params.grade) return `${params.level}/${params.grade}`;
			else return x;
		});

		const path = redirectLinks.join("/");

		if (path == "") navigate("/");
		else navigate(path);
	};

	return (
		<div id="breadcrumbs">
			{items.map((link, i) => {
				const isLast = i === items.length - 1 ? true : false;
				return (
					<React.Fragment key={i}>
						<div
							className={clsx({ breadcrumb: true, active: isLast })}
							onClick={() => handleRedirect(i)}>
							{i == 0 && <LeftArrowIcon className="small-icon" />}
							<span>{link}</span>
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default Breadcrumbs;
