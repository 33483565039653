import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainContainer from "../../components/layout/MainContainer";
import RedBarText from "../../components/templates/RedBarText/RedBarText";
import BookCard from "../../components/templates/BookCard/BookCard";
import Breadcrumbs from "../../components/templates/BreadCrumbs/BreadCrumbs";
import { Book, ListSubject, MediaFiles, Subject } from "../../models";
import BooksFilterSection from "./components/BooksFilterSection";
import getStudentState from "../../helpers/StudentState.utility";
import NotFoundIcon from "../../assets/icons/NotFoundIcon";
import { useFetchAndLoad } from "../../hooks/useFetchAndLoad.hook";
import { useAsync } from "../../hooks/useAsync.hook";
import { getSubjectsByGrade } from "../../services/Subject.service";
import { useGrade } from "../../hooks/useGrade.hook";
import LoadingIcons from "react-loading-icons";
import MediaCard from "../../components/templates/MediaCard/MediaCard";

const BooksList: React.FC = () => {
	const params = useParams();
	const { loadingGrade, grade } = useGrade();
	const studentState = getStudentState(params);
	const { loading, callEndpoint } = useFetchAndLoad();
	const [subjects, setSubjects] = useState<ListSubject[]>([]);
	const [baseSubjects, setBaseSubjects] = useState<string>("[]");

	const getSubjectsByGradeApiCall = async () => grade && (await callEndpoint(getSubjectsByGrade(grade?.id!)));

	const adaptData = (backData: any) => {
		if (backData?.data) {
			const subjectData = backData.data
				.filter((x: Subject) => x.books && x.books.length > 0)
				.map((subject: Subject) => ({ ...subject, show: true }));
			setBaseSubjects(JSON.stringify(subjectData));
			filter(subjectData);
		}
	};

	const filter = (subjectsData: ListSubject[] = JSON.parse(baseSubjects)) => {
		const searchField = document.getElementById("filter-search") as HTMLFormElement;
		const text = searchField.value;

		const allowedSubjects = subjectsData.map((subject) => {
			if (subject.show === true) return subject.name;
		});

		let filterData = subjectsData.filter((x) => allowedSubjects.includes(x.name));

		filterData = filterData.map((subject) => {
			subject.books = JSON.parse(
				JSON.stringify(subject.books?.filter((x) => x.title.toLowerCase().includes(text.toLowerCase())))
			);
			return subject;
		});

		filterData = filterData.filter((x) => x.books!.length > 0);

		setSubjects(filterData);
	};

	useAsync(getSubjectsByGradeApiCall, adaptData, [grade]);

	return (
		<MainContainer id="BookList" left={true} palace={subjects.length > 0}>
			<div className="book-list-page">
				<Breadcrumbs history={["Libro Abierto", studentState]} />
				<section className="books-list-container">
					<div className="books-list">
						{!loadingGrade && !loading ? (
							grade ? (
								subjects.length > 0 ? (
									subjects.map((subject, i) => {
										if (subject.books.length > 0 || subject.mediaFiles.length > 0)
											return (
												<section className={`${subject.code}-books book-section`} key={i}>
													<RedBarText
														title={subject.name}
														link={{ to: subject.name, state: { subject } }}
														redirectText="Ver asignatura y contenido multimedia"
													/>
													<div className="books-container">
														{subject.books.map((book, j) => {
															const booksData = book as Book;
															return (
																<BookCard
																	grade={grade}
																	book={booksData}
																	key={`s${i}-b${j}`}
																/>
															);
														})}
														{subject.mediaFiles.map((media, j) => {
															const mediaData = media as MediaFiles;
															return <MediaCard media={mediaData} key={`s${i}-b${j}`} />;
														})}
													</div>
												</section>
											);
									})
								) : (
									<div className="not-found">
										<NotFoundIcon className="huge-less-icon" />
										<div>No se encuentran libros con su búsqueda</div>
									</div>
								)
							) : (
								<div className="no-grade">
									<NotFoundIcon className="huge-less-icon" />
									<div className="not-found">Debe seleccionar un curso para ver libros</div>
									<Link className="home-link" to="/">
										Libro Abierto
									</Link>
								</div>
							)
						) : (
							<div className="loading-container">
								<div className="loading-component">
									<LoadingIcons.Oval
										className="loadin-icon"
										stroke="#00ACCE"
										speed={2}
										width="6rem"
										height="6rem"
										strokeWidth="3"
									/>
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
			<BooksFilterSection filter={filter} subjects={JSON.parse(baseSubjects)} />
		</MainContainer>
	);
};

export default BooksList;
