import React from "react";
import { Link } from "react-router-dom";
import { Book, Grade } from "../../../models";
import { getLevelName } from "../../../helpers/LevelData.utility";

interface BookCardProps {
	grade?: Grade;
	book: Book;
	noRedirect?: boolean;
}

const BookCard: React.FC<BookCardProps> = ({ grade, book, noRedirect = false }) => {
	return (
		<article className="book-card">
			<Link
				to={
					!noRedirect ? `/${getLevelName(grade?.level!)}/${grade?.name}/${book.subjectName}/${book.id}` : "#"
				}>
				<div className="image-container">
					<img src={book.coverImageUrl} alt="Imagen de Libro" />
				</div>
				<div className="info-container">
					<div className="text-container">
						<h5 className="title">{book.title}</h5>
						<p className="autor">{book.autor?.split(",")[0] || book.autor}</p>
					</div>
					<div className="editorial-container">
						<p className="editorial">{book.publisher}</p>
					</div>
				</div>
			</Link>
		</article>
	);
};

export default BookCard;
